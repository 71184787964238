import React, { useLayoutEffect } from "react";
import Homepage from "./pages/Homepage";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HowItWorks from "./pages/HowItWorks";
import EmailVerification from "./pages/EmailVerification";
import DataPrivacy from "./pages/DataPrivacy";
import AboutUs from "./pages/AboutUs";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import PersonalInformation from "./pages/PersonalInformation";
import PaymentInformation from "./pages/PaymentInformation";
import Dashboard from "./pages/Dashboard";
import ComingSoon from "./pages/ComingSoon";

function App() {
  const location = useLocation();
  const Wrapper = ({ children }) => {
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, []);
    return children;
  };

  return (
    <div className="font-DMSans bg-white w-screen min-w-[350px]">
      <Wrapper>
        {/* {location.pathname.toLowerCase().indexOf("/dashboard") === -1 && (
          <Navbar />
        )} */}
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          {/* <Route path="/howItWorks" element={<HowItWorks />} />
          <Route path="/emailVerification" element={<EmailVerification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/dataPrivacy" element={<DataPrivacy />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route
            path="/personalInformation"
            element={<PersonalInformation />}
          />
          <Route path="/paymentInformation" element={<PaymentInformation />} />
          <Route path="/dashboard/*" element={<Dashboard />} /> */}
        </Routes>
        {/* {location.pathname.toLowerCase() !== "/emailverification" &&
          location.pathname.toLowerCase() !== "/login" &&
          location.pathname.toLowerCase() !== "/forgetpassword" &&
          location.pathname.toLowerCase().indexOf("/dashboard") === -1 && (
            <Footer />
          )} */}
      </Wrapper>
    </div>
  );
}

export default App;