import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Visualization = () => {
  const data = {
    labels: [],
    datasets: [
      {
        data: [68, 32],
        backgroundColor: ["#87DDFD", "#FFF"],
        hoverBackgroundColor: ["#87DDFC", "#FFF"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="md:h-[20rem] w-full bg-primary rounded-xl grid p-6 gap-4 grid-flow-row md:grid-flow-col text-white">
      <div className="flex flex-col h-full text-center md:text-start">
        <h2 className="mb-auto text-xl md:text-lg lg:text-xl font-medium">
          Afford Payments Progress
        </h2>
        <div className="hidden md:flex flex-col gap-1">
          <h2 className="text-xl lg:text-3xl">$55,000.00</h2>
          <p className="text-xs lg:text-sm">
            You reached <b>$55,000 / $75,000</b>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center relative">
        <div className="w-[15rem] md:w-[12rem] lg:w-[15rem] h-[15rem] md:h-[12rem] lg:h-[15rem]">
          <Doughnut data={data} options={options} />
        </div>
        <div className="bg-primary w-[10rem] md:w-[8rem] lg:w-[10rem] h-[10rem] md:h-[8rem] lg:h-[10rem] rounded-full absolute top-[2.9rem] md:top-[4.8rem] lg:top-[3.75rem] grid place-content-center text-center">
          <h2 className="font-bold text-2xl">68%</h2>
          <p className="text-xs">$55,000 / $75,000</p>
        </div>
      </div>
      <div className="hidden xl:grid justify-end items-end">
        <img className="w-[18rem]" src="/Money.svg" alt="Money" />
      </div>
      <div className="flex md:hidden flex-col gap-1 text-center">
          <h2 className="text-xl lg:text-3xl">$55,000.00</h2>
          <p className="text-xs lg:text-sm">
            You reached <b>$55,000 / $75,000</b>
          </p>
        </div>
    </div>
  );
};

export default Visualization;
